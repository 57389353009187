<template>
	<router-view/>
</template>
<script>
import Session from "supertokens-web-js/recipe/session"

export default {
	watch: {
		'$route.query': {
			handler(newQuery) {
				if(newQuery.login === 'ok') this.authenticateUser()
			},
			immediate: true 
		},
		user(newVal) {
			if (!newVal) {
				this.$socket.disconnect()
			}
		},
	},
	methods: {
		async authenticateUser() {
			try {
				const authenticated = await Session.doesSessionExist()

				if (authenticated) {
					if (!this.$socket.connected) {
						this.$socket.connect()
						const userId = await Session.getUserId()
						this.$store.dispatch('checkUser',{
							sub: userId
						})
					}
				} else {
					console.log('User is not authenticated')
				}
			} catch (error) {
				console.error('Error during authentication or socket connection:', error)
			}
		}
	},
	async created() {
		try {
			await this.authenticateUser()
		} catch (error) {
			console.error('Error during authentication:', error)
		}
	},
}
</script>
<style>
@import './assets/css/fontawesome.min.css';
@import './assets/css/sharp-solid.min.css';

/*
YUI 3.18.1 (build f7e7bcb)
Copyright 2014 Yahoo! Inc. All rights reserved.
Licensed under the BSD License.
http://yuilibrary.com/license/
*/
html{color:#000;background:#FFF}body,div,dl,dt,dd,ul,ol,li,h1,h2,h3,h4,h5,h6,pre,code,form,fieldset,legend,input,textarea,p,blockquote,th,td{margin:0;padding:0}table{border-collapse:collapse;border-spacing:0}fieldset,img{border:0}address,caption,cite,code,dfn,em,strong,th,var{font-style:normal;font-weight:normal}ol,ul{list-style:none}caption,th{text-align:left}h1,h2,h3,h4,h5,h6{font-size:100%;font-weight:normal}q:before,q:after{content:''}abbr,acronym{border:0;font-variant:normal}sup{vertical-align:text-top}sub{vertical-align:text-bottom}input,textarea,select{font-family:inherit;font-size:inherit;font-weight:inherit;*font-size:100%}legend{color:#000}#yui3-css-stamp.cssreset{display:none}

html{box-sizing:border-box}*,:after,:before{box-sizing:inherit}
html,body {font-family:Arial,Helvetica,sans-serif;font-size: 62.5%;}

@font-face {
	font-family: 'Work Sans Regular';
	src: url(./assets/webfonts/work-sans/WorkSans-Regular.ttf);
}

@font-face {
	font-family: 'Work Sans Medium';
	src: url(./assets/webfonts/work-sans/WorkSans-Medium.ttf);
}

html,body {
	font-family: 'Work Sans Regular', sans-serif;
}



:root {
	--default-orange: #f37e60;
	--default-orange-hover: #d63d15;
	--default-purple: #c489f8;
	--dark-purple: #250852;
	--light-purple: #D2A0FF;
	--dark-red: #8B0000;
	--error: #5C0120;
	--success: #1A3A3A;
}



.modal,
.modal-add-edit,
dialog {
	/* From https://css.glass */
	box-shadow: 0 3px 12px rgba(0, 0, 0, 0.12);
	backdrop-filter: blur(6px);
	-webkit-backdrop-filter: blur(6px);
}

dialog {
	background-color: rgba(255,255,255,.81);
}

dialog::backdrop {
	background-color: rgba(0, 0, 0, 0.39);
}

.utils {
	background-color: rgba(0,0,0,.01);
}



.utils {
	align-content: center;
}

header,
header .container,
.grid-container > div,
.grid-container .info,
#view-unit,
#view-unit .container,
#unit-title-ver,
#deliver-utility {
	align-items: center;
}

.view.admin main,
.form-general {
	background-color: #fff;
}

header,
body {
	background-color: var(--default-purple);
}

input[readonly],
textarea[readonly] {
	background-color: #ccc;
}

#download-url input {
	background-color: rgba(240,255,0,.15);
}

#deliver-utility {
	background-color: rgba(231,231,231,.15);
}

#view-unit {
	background-color: #2f2f2f;
}

.modal,
.modal-add-edit {
	background: rgba(255, 255, 255, 0.69);
}

input,
select,
textarea {
	background-color: rgba(255,255,255,0.84);
}

input:focus,
select:focus,
textarea:focus {
	background-color: rgba(255,255,255,1);
}

button {
	background-color: var(--default-orange);
}

button:hover {
	background-color: var(--default-orange-hover);
}

a.info:hover {
	background-color: #a870d8;
}

#sub-nav-container {
	background-color: #585856;
}

table th {
	background-color: var(--dark-purple);
}

.modal-add-edit legend {
	background-color: #fff;
}

.form-general {
	border: 1px solid var(--dark-purple);
}

input,
select,
textarea {
	border: 1px solid rgba(0,0,0,0.84);
}

input:focus,
select:focus,
textarea:focus {
	border: 1px solid rgba(0,0,0,1);
}

.modal-add-edit,
#deliver-utility,
table th,
table td {
	border: 1px solid rgba(204,204,204,.69);
}

fieldset + fieldset {
	border-top: 1px solid #ccc;
}

#deliver-utility {
	border-bottom: 1px solid #ccc;
}

.modal,
dialog {
	border: 1px solid rgba(255, 255, 255, 0.15);
}

.grid-container > div,
.utils {
	border: 1px solid #f4f4f4;
}

.view.admin header,
main h1,
main .archive-container h2,
#project-all > li {
	border-bottom: 1px solid var(--dark-purple);
}

button,
iframe,
#dashboard .grid-container > div,
#project-all > li:last-child {
	border: 0 none;
}

#project-all > li {
	clear: both;
}

button,
header a,
#sub-nav-container,
#sub-nav-container a,
.link-sm a,
.prev-next,
#unit-title-ver p,
table th,
#clients-grid a.info:hover,
#projects-grid a.info:hover,
#campaigns-grid a.info:hover,
#units-grid a.info:hover {
	color: #fff;
}

main a,
#projects-grid a,
#projects-grid a:visited,
.zip-files.link {
	color: #a870d8;
}

legend,
main h1,
main h2,
#dashboard .grid-container > div,
.fa-pipe,
.kb-ver,
.close {
	color: var(--dark-purple);
}

.none-found {
	color: var(--light-purple);
}

.error {
	color: var(--error);
}

.success {
	color: var(--success);
}

#sub-nav-container a.router-link-exact-active,
.link-active-container {
	color: #ffff00;
}

.router-link-active {
	color: rgba(37,8,72,0.60);
}

.default-poster {
	color: var(--dark-red);
}

.prev-next,
label,
button,
.link,
.close,
#tree-view,
#tree ul > li ul a {
	cursor: pointer;
}

.single-modal-container,
.grid-container {
	display: grid;
}

label,
button,
iframe,
header a,
header nav span {
	display: block;
}

header,
header .container,
header ul,
.view.admin,
#sub-nav-container,
#sub-nav-container ul,
.grid-container > div,
.grid-container .info,
#view-units,
#view-units main,
#view-unit,
#view-unit .container,
#unit-title-ver,
.field-collection,
#deliver-utility,
#res-msg {
	display: flex;
}

.view.admin,
#view-units,
#view-units main,
#view-unit {
	flex-direction: column;
}

.view.admin main,
.grid-container .info,
#unit-tree-container,
#view-units main,
#view-unit {
	flex-grow: 1;
}

.field-collection,
#deliver-utility {
	flex-wrap: wrap;
}

.unit-list li {
	float: left;
}

legend,
label,
button,
#res-msg {
	font-family: 'Work Sans Medium', sans-serif;
}

#project-all label {
	font-family: 'Work Sans Regular', sans-serif;
}

.close {
	font-size: 3.6rem;
}

main h1 {
	font-size: 3.3rem;
}

main h2 {
	font-size: 2.7rem;
}

legend {
	font-size: 2.4rem;
}

header nav i {
	font-size: 2.22rem;
}

main .modal-add-edit h2,
#dashboard .grid-container > div,
dialog {
	font-size: 2.1rem;
}

main p,
.grid-container > div .utils,
label,
input,
select,
textarea,
button {
	font-size: 1.8rem;
}

#sub-nav-container,
.grid-container > div,
.link-sm,
#unit-title-ver select,
.modal-add-edit legend,
#view-project-deliver ul,
#deliver-utility,
#deliver-utility label,
#project-all label{
	font-size: 1.5rem;
}

.client-rates legend,
#download-url input,
.field-collection-estimate,
.field-collection-estimate label,
.field-collection-estimate input,
.field-collection-estimate select,
table,
table input,
.modal-add-edit ol {
	font-size: 1.35rem;
}

header nav span,
label em,
.archive-container,
.kb-ver {
	font-size: 1.2rem;
}

em {
	font-style: italic
}

header li span,
#sub-nav-container li span,
#unit-title-ver p,
table th {
	font-weight: bold;
}

#project-all label,
a.info:hover .kb-ver {
	font-weight: normal;
}

header ul,
.grid-container,
#view-unit .container,
#unit-title-ver,
.field-collection {
	gap: 12px;
}

#sub-nav-container ul,
.grid-container > div,
#deliver-utility {
	gap: 9px;
}

.grid-container .info,
#res-msg {
	gap: 3px;
}

#clients-grid.grid-container > div,
#projects-grid.grid-container > div,
#campaigns-grid.grid-container > div,
#units-grid.grid-container > div {
	gap: 0;
}

.grid-container {
	grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
}


header {
	height: 72px;
}

.utils,
a.info {
	height: 100%;
}

.single-modal-container {
	height: 100svh;
}

.field-collection,
#view-unit,
#res-msg {
	justify-content: center;
}

header .container,
#sub-nav-container,
#view-unit .container,
#unit-title-ver {
	justify-content: space-between;
}

.modal-add-edit {
	left: 50%;
}

#logo-standalone {
	left: 18px;
}

main h1,
main h2 {
	line-height: 1.5;
}

#project-all label {
	line-height: 1.38;
}

legend,
label,
#res-msg {
	line-height: 1.29;
}

.modal-add-edit ol {
	list-style-type: decimal;
}

main h1 {
	margin-bottom: 48px;
}

.field-group {
	margin-bottom: 18px;
}

main h2,
#res-msg {
	margin-bottom: 15px;
}

legend,
#deliver-utility {
	margin-bottom: 12px;
}

label,
.unit-list li {
	margin-bottom: 3px;
}

#deliver-utility label {
	margin-bottom: 0;
}

main {
	margin-top: 72px;
}

.archive-container {
	margin-top: 40px;
}

#sub-nav-container + div,
#view-unit > .container {
	margin-top: 35px;
}

#unit-title-ver,
#download-url {
	margin-top: 9px;
}

#project-all > li ul label {
	margin-right: 12px;
}

.modal-add-edit ol {
	margin-left: 3ch;
}

.zip-files {
	margin-left: auto;
}

button {
	margin: 0 auto;
}

#project-all > li > label {
	margin-bottom: 6px;
}

.grid-container img {
	margin: 3px;
}

.view-main-content > .container,
.form-general,
legend,
#iframe-container,
iframe {
	margin: 0 auto;
}

.none-found p,
table {
	margin-bottom: 1.26rem;
}

#view-project-deliver #project-all {
	max-height: 426px;
}

.unit-backup img {
	max-height: 90px;
}

.field-collection .field-group {
	max-width: 30%;
}

.unit-backup img {
	max-width: 120px;
}

.form-general {
	max-width: 480px;
}

dialog {
	min-width: 240px;
}

.view {
	min-height: 100svh;
}

.info {
	min-height: 48px;
}

header li:hover,
#sub-nav-container nav li:hover,
input[readonly],
textarea[readonly] {
	opacity: 0.78;
}

input:focus,
select:focus,
textarea:focus {
	outline: 0 none;
}

#project-all > li {
	overflow: hidden;
}

#view-project-deliver #project-all {
	overflow-y: scroll;
}

dialog {
	padding: 30px;
}

.modal,
.modal-add-edit,
.form-general fieldset {
	padding: 18px;
}

#project-all > li {
	padding: 12px 3px;
}

.view-main-content > .container,
#view-campaign-edit > .container {
	padding: 24px 18px;
}

.container {
	padding: 12px 18px;
}

button,
.prev-next {
	padding: 12px 18px;
}

#sub-nav-container > ul,
#sub-nav-container > nav {
	padding: 9px 12px;
}

input,
select,
textarea {
	padding: 6px 9px;
}

header nav a,
#sub-nav-container a,
.grid-container .info,
legend,
#project-all > li ul {
	padding: 0 6px;
}

.grid-container a,
.grid-container span,
.close,
#deliver-utility {
	padding: 6px;
}

/* .form-general fieldset + fieldset {
	padding-top: 0;
} */

header nav i,
main h1,
main h2 {
	padding-bottom: 6px;
}

#unit-title-ver,
table th,
table td {
	padding: 6px;
}

#deliver-utility > div {
	padding: 3px;
}

.single-modal-container {
	place-items: center;
}

#logo-standalone,
.close,
.kb-ver {
	position: absolute;
}

main,
.info.unit-backup {
	position: relative;
}

header,
#sub-nav-container,
.modal-add-edit {
	position: fixed;
}

.archive-container  h2 {
	text-align: left;
}

legend,
nav,
#projects-grid > div > div:first-child,
main h2,
table th {
	text-align: center;
}

.total {
	text-align: right;
}

header a,
#sub-nav-container a,
.info {
	text-decoration: none;
}

legend,
button {
	text-transform: uppercase;
}

.modal-add-edit {
	top: 50%;
}

#logo-standalone {
	top: 18px;
}

.close {
	top: 0;
}

.modal-add-edit {
	transform: translate(-50%,-50%);
}

.close {
	transform: translate(50%,-50%);
}

button,
header li i,
header li span,
a.info {
	transition: all 180ms ease-in-out;
}

.close {
	right: 0;
}

.modal {
	width: 315px;
}

table {
	width: 100%;
}

header,
header .container,
#sub-nav-container,
#view-unit .container,
#download-url,
input,
select,
textarea {
	width: 100%;
}

#unit-title-ver select,
input[type="checkbox"] {
	width: auto;
}

header {
	z-index: 9;
}

#sub-nav-container {
	z-index: 3;
}

.modal-add-edit {
	z-index: 9;
}



.info span {
	word-break: break-all;
	overflow-wrap: break-word;
	white-space: normal; /* Ensure text wraps normally */
}



#iframe-container {
	min-width: 160px;
	min-height: 50px;
}



.archive-container  li {
	float: left;
	margin: 0 9px;
	margin-bottom: 6px;
}

	.archive-container  li a {
		line-height: 1.26;
	}

.close {
	text-shadow: 1px 1px 2px rgba(255,255,255,.69)
}
</style>
